import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import YetiLogo from "./../images/svg/yeti-logo.svg"
import useToggleState from "./../hooks/useToggleState"

const Header = ({ koLogo }) => {
  const [menuOpen, toggleMenu] = useToggleState(false);

  // use this so when trailing slashes are included in a refresh the active link is still highlighted
  const isPartiallyActive = ({isPartiallyCurrent}) => {
    return isPartiallyCurrent
      ? { className: "text-pink" }
      : {}
  }

  const onHomePage = ({isCurrent}) => {
    return isCurrent 
    ? {className: "text-pink md:text-white"}
    : {}
  }

  // put in useEffect because Amplify will throw an error trying to find document
  useEffect(() => {
    menuOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = ''
  }, [menuOpen])

  return (
    <header className="
            absolute 
            w-full 
            left-0 top-0 
            flex flex-col justify-center md:block
            z-40
           ">
      {/* DESKTOP NAV */}
      <nav className="
            hidden md:flex
            w-11/12 max-w-3xl h-auto 
            top-0 left-full md:left-auto
            flex-row justify-between items-center
            mx-auto p-7
            font-medium text-xl lg:text-2xl
            bg-transparent
            duration-200"
            >
        <Link to="/" id="home" className="text-white lg:hover:text-pink duration-200">Home</Link>
        <Link to="/our-talent" getProps={isPartiallyActive} id="our-talent" className="text-white lg:hover:text-pink duration-200">Our Talent</Link>
        <div className="block w-28 lg:w-32">
          <Link to="/">
            <YetiLogo id="yeti-logo" className="w-full" style={ {fill: `${!koLogo ? '#DD004A' : '#FFFFFF'}`} }/>
          </Link>
        </div>
        <Link to="/our-work" getProps={isPartiallyActive} id="our-work" className="text-white lg:hover:text-pink duration-200">Our Work</Link>
        <Link to="/about" getProps={isPartiallyActive} id="about" className="text-white lg:hover:text-pink">About</Link>
      </nav>

      {/* MOBILE NAV */}
      <nav className={`
            fixed md:hidden
            w-full h-full
            top-0 left-full
            flex flex-col justify-center items-center space-y-16
            text-4xl font-medium
            duration-200
            ${menuOpen ? 'transform -translate-x-full' : ''}`}
            onClick={() => toggleMenu()}
            onKeyPress={() => toggleMenu()}
            role="button"
            tabIndex={-1}
            >
        <Link to="/" id="home" getProps={onHomePage} className="text-white">Home</Link>
        <Link to="/our-talent" getProps={isPartiallyActive} id="our-talent" className="text-white">Our Talent</Link>
        <div className="hidden md:block w-28 lg:w-32">
          <Link to="/">
            <YetiLogo id="yeti-logo" className={ `${koLogo ? 'text-white' : 'text-pink'} fill-current w-full` } />
          </Link>
        </div>
        <Link to="/our-work" getProps={isPartiallyActive} id="our-work" className="text-white">Our Work</Link>
        <Link to="/about" getProps={isPartiallyActive} id="about" className="text-white">About</Link>
      </nav>


      {/* mobile header */}
      <div className="absolute top-0 left-0 w-full md:hidden flex justify-between p-6 items-center">
        <div className="w-24">
          <Link to="/">
            <YetiLogo className={ `${koLogo ? 'text-white' : 'text-pink'} fill-current w-full` } />
          </Link>
        </div>
        <div id="mobile-menu" className="w-11 h-7 relative flex flex-col justify-between duration-500 focus:outline-none active:outline-none" 
              role="button" 
              tabIndex={-1} 
              onClick={() => toggleMenu()} 
              onKeyPress={() => toggleMenu()} >
          <div id="top" className={`${menuOpen ? 'menuOpen bg-black' : ''} h-1 origin-center bg-white`}></div>
          <div id="middle" className={`${menuOpen ? 'menuOpen bg-black' : ''} h-1 origin-center bg-white`}></div>
          <div id="bottom" className={`${menuOpen ? 'menuOpen bg-black' : ''} h-1 origin-center bg-white`}></div>
        </div>
      </div>
    </header>
  )
}


Header.propTypes = {
  koLogo: PropTypes.bool
}

Header.defaultProps = {
  koLogo: false
}

export default Header